import React, { useEffect, useState, useRef } from 'react';
import './App.css'; // Make sure to create a corresponding CSS file for styling
import Select from 'react-select';
import CountryFlag from 'react-country-flag';
import { ProgressBar } from "react-progressbar-fancy";
import { getProject, createProject, deleteProject, getMe, getSourcingsByProject, generateAIKeywords, updateProjectStatus, getLastSourcingByProject, getProjectProgress, getKeywordsByProject, getProjectKeywordsStatus, updateKeyword, deleteKeyword } from './api'; // Import the API functions
import { useLocation } from 'react-router-dom';
import { SlHome, SlTrash, SlPencil, SlPaperPlane } from "react-icons/sl";
import { Puff, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Rings, SpinningCircles, TailSpin, ThreeDots } from 'react-loading-icons'
import { HiCheck } from "react-icons/hi2";
import * as XLSX from 'xlsx';
import { MdOutlineFileDownload } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'; // You'll need to install this package


const AutomatedSourcingTool = ({ loadedProj }) => {
  const [isStarting, setIsStarting] = useState(false); // New state for starting
  const [isPausing, setIsPausing] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [aiKeywords, setAiKeywords] = useState({});
  const [excludedWebsites, setExcludedWebsites] = useState(['Only selected countries', 'No min. employees count']);
  const [chargeCode, setChargeCode] = useState(''); // Initialize with an empty string
  const [clientName, setClientName] = useState(''); // Initialize with an empty string
  const [requestDate, setRequestDate] = useState(new Date().toISOString().split('T')[0]);
  const [newKeyword, setNewKeyword] = useState('');
  const [lastAddedIndex, setLastAddedIndex] = useState(null);
  const [removingIndex, setRemovingIndex] = useState(null);
  const [removingAiIndex, setRemovingAiIndex] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]); // New state for selected languages
  const [searchDepth, setSearchDepth] = useState(2);
  const [resultPages, setResultPages] = useState(100);
  const [isSourcingStarted, setIsSourcingStarted] = useState(false);
  const [revealLines, setRevealLines] = useState(false);
  const [sourcingProgress, setSourcingProgress] = useState(0);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null); // New state for category filter
  const [isSourcingActive, setIsSourcingActive] = useState(false); // New state to track if sourcing is active
  const [loading, setLoading] = useState(false);
  const [showKeywordsOverlay, setShowKeywordsOverlay] = useState(false);
  const [category, setCategory] = useState(''); // New state for category
  const [selectedCategories, setSelectedCategories] = useState([]); // State to store multiple selected categories
  const [isRecapHovered, setIsRecapHovered] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [minEmployees, setMinEmployees] = useState(''); // Add this state to store the minimum employees count
  const [completedRules, setCompletedRules] = useState([]); // New state to track if sourcing is active
  const location = useLocation();
  const [loadedID, setLoadedID] = useState(location.state?.loadedProj);
  const [isGeneratingAIQueries, setIsGeneratingAIQueries] = useState(false);
  const [status, setStatus] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedEngines, setSelectedEngines] = useState(['GOOGLE']);

  const Modal = ({ onClose, onConfirm, show }) => {
    if (!show) return null;

    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ backgroundColor: '#333333', padding: 20, borderRadius: 5, width: '300px', textAlign: 'center' }}>
          <h2>Export Options</h2>
          <p>Which websites do you wish to export ?</p>
          <button onClick={() => onConfirm('relevant')}>Only relevant websites</button>
          <button onClick={() => onConfirm('relevantAndMaybe')}>Relevant and potentially relevant websites</button>
          <button onClick={() => onConfirm('all')}>All websites</button>

          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };
  const handleExportSourcing = () => {
    setShowModal(true); // Show the modal to choose export options
  };

  const confirmExport = async (exportOption) => {
    // Fetch latest sourcing results before exporting
    await fetchSourcingsAndUpdateResults();
    let filteredResultsData;
    if (exportOption === 'relevant') {
      filteredResultsData = resultsData.filter(sourcing => sourcing.relevance === 'Yes');
    } else if (exportOption === 'relevantAndMaybe') {
      filteredResultsData = resultsData.filter(sourcing => sourcing.relevance === 'Yes' || sourcing.relevance === 'Maybe');
    } else {
      filteredResultsData = resultsData;
    }    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Sheet 1: Project Information
    const projectInfoSheet = workbook.addWorksheet('Project Information');

    // Project Information data
    const projectInfo = [
      ['Charge Code', chargeCode || 'N/A'],
      ['Buyer Name', buyerName || 'N/A'],
      ['Buyer Email', buyerEmail || 'N/A'],
      ['Client Name', clientName || 'N/A'],
      ['Request Date', requestDate || 'N/A'],
      ['Category', category || 'N/A'],
      ['Keywords', (keywords && keywords.join(', ')) || 'N/A'],
      ['Countries', (selectedCountries && selectedCountries.map(country => country.label).join(', ')) || 'N/A'],
      ['Languages', (selectedLanguages && selectedLanguages.map(language => language.label).join(', ')) || 'N/A'],
      ['Result Pages', resultPages ? resultPages / 10 : 'N/A'],
      ['Minimal Contact Information', searchDepth || 'N/A'],
    ];

    // Add headers and data in columns
    projectInfo.forEach((row, index) => {
      const [header, data] = row;
      const excelRow = projectInfoSheet.getRow(index + 1); // ExcelJS is 1-based
      const headerCell = excelRow.getCell(1); // First column for headers
      const dataCell = excelRow.getCell(2); // Second column for data

      // Set header and data values with centered alignment
      headerCell.value = header;
      headerCell.alignment = { vertical: 'middle', horizontal: 'center' };
      dataCell.value = data;
      dataCell.alignment = { vertical: 'middle', horizontal: 'center' };
      // Style headers: White font on a dark red background, vertically aligned
      headerCell.font = { bold: true, size: 12, color: { argb: 'FFFFFFFF' } }; // White font
      headerCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF640513' }, // Dark red background
      };
      headerCell.alignment = { vertical: 'middle', horizontal: 'center' }; // Vertically align the text

    });

    // Adjust column widths to fit content
    projectInfoSheet.columns.forEach(column => {
      let maxLength = 10; // Set a default minimum length
      column.eachCell({ includeEmpty: true }, cell => {
        if (cell.value) {
          const cellLength = cell.value.toString().length;
          if (cellLength > maxLength) {
            maxLength = cellLength;
          }
        }
      });
      column.width = maxLength + 2; // Add some padding
    });

    // Sheet 2: AI Keywords
    const aiKeywordsSheet = workbook.addWorksheet('AI Keywords');
    const aiKeywordsData = Object.entries(aiKeywords).map(([index, { keyword, country, language, engine }]) => ({
      Keyword: keyword,
      Country: country,
      Language: language,
      Engine: engine,
    }));

    // Add headers and rows for AI Keywords
    if (aiKeywordsData.length > 0) {
      const aiKeywordsHeader = Object.keys(aiKeywordsData[0]);
      const headerRow = aiKeywordsSheet.addRow(aiKeywordsHeader);
      headerRow.font = { bold: true, size: 12, color: { argb: 'FFFFFFFF' } }; // White font
      headerRow.height = 18;

      headerRow.eachCell(cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF640513' }, // Dark red background
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center align the text vertically and horizontally
      });

      aiKeywordsData.forEach(data => aiKeywordsSheet.addRow(Object.values(data)));

      // Adjust column widths to fit content
      aiKeywordsSheet.columns.forEach(column => {
        let maxLength = 10; // Set a default minimum length
        column.eachCell({ includeEmpty: true }, cell => {
          if (cell.value) {
            const cellLength = cell.value.toString().length;
            if (cellLength > maxLength) {
              maxLength = cellLength;
            }
          }
        });
        column.width = maxLength + 2; // Add some padding
      });
    }

    // Sheet 3: Sourcing Details
    const sourcingDetailsSheet = workbook.addWorksheet('Sourcing Details');

    const sourcingDetailsData = filteredResultsData.map(sourcing => ({
      'AI Relevance': sourcing.relevance,
      'Manual Relevance': '', // New column for manual relevance
      'Reason for Mismatch (if any)': '', // New column for reason for mismatch
      'AI Analysis': sourcing.analysis,
      'Company Name': sourcing.companyName,
      'Local Name': sourcing.localName,
      'Business Type': '',
      'Supplier Quality': '',
      'Products/Services': '',
      'Address with postal code': `${sourcing.address ? sourcing.address : ''}${sourcing.postalCode ? `${sourcing.address ? ', ' : ''}${sourcing.postalCode}` : ''}`.trim(),
      'City': sourcing.city,
      'Province': sourcing.province,
      'Country': sourcing.country,
      'Company Website': sourcing.website,
      'Primary Contact Name': sourcing.primaryContactName,
      'Primary Contact Title': sourcing.primaryContactTitle,
      'Primary Contact Telephone': sourcing.primaryContactTelephone,
      'Primary Contact Mobile': sourcing.primaryContactMobile,
      'Primary Contact Email': sourcing.primaryContactEmail,
      'Last Rfx': '',
      'Last Audit': '',
      'Last Order': '',
      'Comments': '',
      'Source': '',
      'Selected': '',
      // Add more sourcing fields here as needed
    }));

    // Add headers and rows for Sourcing Details
    if (sourcingDetailsData.length > 0) {
      const sourcingDetailsHeader = Object.keys(sourcingDetailsData[0]);
      const headerRow = sourcingDetailsSheet.addRow(sourcingDetailsHeader);
      headerRow.font = { bold: true, size: 12, color: { argb: 'FFFFFFFF' } }; // White font
      headerRow.height = 18;

      headerRow.eachCell(cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF640513' }, // Dark red background
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center align the text vertically and horizontally
      });

      sourcingDetailsData.forEach(data => {
        const row = sourcingDetailsSheet.addRow(Object.values(data));

        // Make the "Company Name" bold
        const companyNameIndex = sourcingDetailsHeader.indexOf('Company Name');
        if (companyNameIndex !== -1) {
          row.getCell(companyNameIndex + 1).font = { bold: true }; // +1 because ExcelJS is 1-based
          row.getCell(companyNameIndex + 1).alignment = { horizontal: 'center' };
        }

        const aiRelevanceIndex = sourcingDetailsHeader.indexOf('AI Relevance');
        if (aiRelevanceIndex !== -1) {
          row.getCell(aiRelevanceIndex + 1).font = { bold: true }; // +1 because ExcelJS is 1-based
          row.getCell(aiRelevanceIndex + 1).alignment = { horizontal: 'center' };
        }

        // Apply conditional formatting to the "AI Relevance" field
        const relevanceIndex = sourcingDetailsHeader.indexOf('AI Relevance');
        const relevanceCell = row.getCell(relevanceIndex + 1); // +1 because ExcelJS is 1-based

        if (relevanceCell.value === 'Yes') {
          relevanceCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF00B300' }, // Green background
          };
          relevanceCell.font = { color: { argb: 'FFFFFFFF' } }; // White font
        } else if (relevanceCell.value === 'Maybe') {
          relevanceCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF8C00' }, // Orange background
          };
          relevanceCell.font = { color: { argb: 'FFFFFFFF' } }; // White font
        } else if (relevanceCell.value === 'No') {
          relevanceCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF0000' }, // Red background
          };
          relevanceCell.font = { color: { argb: 'FFFFFFFF' } }; // White font
        }
      });

      // Adjust column widths to fit content
      sourcingDetailsSheet.columns.forEach(column => {
        let maxLength = 10; // Set a default minimum length
        column.eachCell({ includeEmpty: true }, cell => {
          if (cell.value) {
            const cellLength = cell.value.toString().length;
            if (cellLength > maxLength) {
              maxLength = cellLength;
            }
          }
        });
        column.width = maxLength + 2; // Add some padding
      });
    }

    // Generate the Excel file and download it
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `${requestDate}_${category}_${clientName.replace(/\s+/g, '_')}.xlsx`;
      saveAs(new Blob([buffer]), fileName);
      console.log(`File saved as ${fileName}`);
    } catch (error) {
      console.error('Error generating the Excel file:', error);
    }
  };


  const [resultsData, setResultsData] = useState([
  ]);

  const resultsDataRef = useRef(resultsData);
  useEffect(() => {
    resultsDataRef.current = resultsData;
  }, [resultsData]);

  async function updateLastSourcing(loadedID, setResultsData) {
    if (!loadedID) return;

    try {
      console.log('Fetching last sourcing...');
      const lastSourcing = await getLastSourcingByProject(loadedID);
      if (lastSourcing && (resultsDataRef.current.length === 0 || lastSourcing.id !== resultsDataRef.current[0].id)) {
        console.log("New sourcing found! Updating results...");
        const newSourcing = {
          id: lastSourcing.id,
          companyName: lastSourcing.company_name,
          relevance: lastSourcing.ai_relevance,
          analysis: lastSourcing.ai_analysis,
          website: lastSourcing.company_website,
          primaryContactName: lastSourcing.primary_contact_name,
          primaryContactTitle: lastSourcing.primary_contact_title,
          primaryContactTelephone: lastSourcing.primary_contact_telephone,
          primaryContactMobile: lastSourcing.primary_contact_mobile,
          primaryContactEmail: lastSourcing.primary_contact_email,
          address: lastSourcing.address,
          postalCode: lastSourcing.postal_code,
          city: lastSourcing.city,
          province: lastSourcing.province,
          country: lastSourcing.country,
          // Add more fields as needed
        };
        setResultsData(prevResultsData => {
          console.log("Before update:", prevResultsData);
          const updatedResultsData = [newSourcing, ...prevResultsData]; // Add the new sourcing at the beginning of the list
          console.log("After update:", updatedResultsData);
          return updatedResultsData;
        });
        const progressData = await getProjectProgress(loadedID);
        if (progressData !== null) {
          console.log('Progress:', progressData.progress);
          // Assuming you have a state named sourcingProgress to control the progress bar
          setSourcingProgress(progressData.progress); // Update the progress bar with the new progress value
        }

      }
    } catch (error) {
      console.error('Failed to fetch last sourcing:', error);
    }
  } const populateAIKeywords = async () => {
    setIsGeneratingAIQueries(true); // Set to true when starting to generate AI keywords
    const countryLabels = selectedCountries.map(country => country.label);
    const languageLabels = selectedLanguages.map(language => language.label);
    console.log('keywords:', keywords);
    console.log('countryLabels:', countryLabels);
    console.log('languageLabels:', languageLabels);
    console.log('engineLabels:', selectedEngines);

    let newProjectID; // Declare newProjectID outside the try block

    try {
      newProjectID = await createNewProject();
      if (!newProjectID) {
        throw new Error('Failed to create project - no project ID returned');
      }
      await generateAIKeywords(newProjectID, keywords, countryLabels, languageLabels, selectedEngines);
      console.log('AI keyword generation initiated for project:', newProjectID);

      const checkKeywordsStatus = async () => {
        try {
          const status = await getProjectKeywordsStatus(newProjectID);
          console.log('AI Keywords Status:', status);

          if (status === "DONE") {
            console.log('Status is DONE, showing keywords overlay');
            setIsGeneratingAIQueries(false);
            setShowKeywordsOverlay(true); // Show the overlay
            console.log('Clearing status check interval');
            clearInterval(statusInterval); // Stop checking once done
            console.log('Fetching AI keywords for project:', newProjectID);
            const aiKeywordsData = await getKeywordsByProject(newProjectID);
            console.log('Fetched AI keywords:', aiKeywordsData);
            setAiKeywords(aiKeywordsData); // Update the aiKeywords state with the fetched queries
            console.log('Updated aiKeywords state:', aiKeywordsData);

          } else {
            console.log('Current status:', status);
          }
        } catch (error) {
          console.error('Error checking AI keywords status:', error);
          // Delete the project if there's an error
          await deleteProject(newProjectID);
          console.log('Project with ID:', newProjectID, 'has been deleted due to error.');
        }
      };

      // Check the status every 10 seconds
      const statusInterval = setInterval(checkKeywordsStatus, 10000);
    } catch (error) {
      console.error('Error generating AI keywords:', error);
      // Delete the project if there's an error
      if (newProjectID) {
        await deleteProject(newProjectID);
        console.log('Project with ID:', newProjectID, 'has been deleted due to error.');
      }
    } finally {
      // Set back to false once AI keywords are generated
    }
  };
  const fetchSourcingsAndUpdateResults = async (projectId) => {
    try {
      const sourcings = await getSourcingsByProject(projectId);
      console.log('Fetched sourcings:', sourcings);
      // Assuming the sourcings data structure matches the expected format for resultsData
      // Adjust the mapping as necessary based on the actual structure of sourcings
      const formattedSourcings = sourcings.map(sourcing => ({
        id: sourcing.id, // Adjust according to actual response structure
        companyName: sourcing.company_name,
        relevance: sourcing.ai_relevance,
        analysis: sourcing.ai_analysis,
        website: sourcing.company_website,
        primaryContactName: sourcing.primary_contact_name,
        primaryContactTitle: sourcing.primary_contact_title,
        primaryContactTelephone: sourcing.primary_contact_telephone,
        primaryContactMobile: sourcing.primary_contact_mobile,
        primaryContactEmail: sourcing.primary_contact_email,
        address: sourcing.address,
        postalCode: sourcing.postal_code,
        city: sourcing.city,
        province: sourcing.province,
        country: sourcing.country,
      }));
      setResultsData(formattedSourcings);
      console.log('Formatted sourcings:', formattedSourcings);
    } catch (error) {
      console.error('Failed to fetch sourcings:', error);
    }
  };

  const fetchProjectDetails = async () => {
    console.log('Fetching project details...');
    if (!loadedID) return; // Exit if no projectId is provided

    setLoading(true);
    try {
      console.log(`Fetching project details for project ID: ${loadedID}`);

      const projectDetails = await getProject(loadedID); // Assuming getProject is an API call that fetches project details
      const mappedCountries = projectDetails.countries?.map(countryLabel =>
        countryOptions.find(option => option.label === countryLabel)) || [];

      const mappedLanguages = projectDetails.languages?.map(languageLabel =>
        languageOptions.find(option => option.label === languageLabel)) || [];

      // Update state with fetched project details
      setKeywords(projectDetails.keywords || []);
      try {
        const keywords = await getKeywordsByProject(loadedID);
        setAiKeywords(keywords || {});
      } catch (error) {
        console.error('Failed to fetch keywords:', error);
        setAiKeywords({});
      }
      setCompletedRules(projectDetails.rules || []);
      setChargeCode(projectDetails.charge_code || '');
      setClientName(projectDetails.client_name || '');
      setBuyerName(projectDetails.buyer_name || '');
      setBuyerEmail(projectDetails.buyer_email || '');
      setRequestDate(projectDetails.start_date || new Date().toISOString().split('T')[0]);
      setSelectedCountries(mappedCountries);
      setSelectedLanguages(mappedLanguages);
      setSearchDepth(projectDetails.min_contact_info || 1);
      setResultPages(projectDetails.result_pages || 10);
      setCategory(projectDetails.category || '');
      setSourcingProgress(projectDetails.progress || 0);
      setStatus(projectDetails.status || 'ONGOING')
      if (projectDetails.status === "ONGOING" || projectDetails.status === "ONGOING_CONFIRM") {
        setIsSourcingActive(true);
      }
      if (projectDetails.status !== "CREATED") {
        setIsSourcingStarted(true);
      }
      console.log('Project details:', projectDetails);
      // Add more state updates as necessary based on the structure of your projectDetails
    } catch (error) {
      console.error('Failed to fetch project details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditKeyword = (index) => {
    setEditingIndex(index); // Set the index of the keyword being edited
    setEditingText(aiKeywords[index].keyword); // Set the initial text of the keyword being edited to the query value
  };

  const handleConfirmEdit = async (id) => {
    console.log('Confirming edit for keyword ID:', id);
    const updatedKeywords = { ...aiKeywords };
    updatedKeywords[editingIndex].keyword = editingText; // Update the keyword at the editing index
    setAiKeywords(updatedKeywords); // Update the aiKeywords object with the edited keyword

    try {
      await updateKeyword(id, updatedKeywords[editingIndex]); // Send full keyword data to API
    } catch (error) {
      console.error('Failed to update keyword:', error);
    }

    setEditingIndex(null); // Reset editing index
    setEditingText(''); // Reset editing text
  };

  const handleEditingTextChange = (e) => {
    setEditingText(e.target.value); // Update the editing text as the user types
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (isSourcingActive) {
        updateLastSourcing(loadedID, setResultsData);
      }
      console.log('Status:', status)
      if (status === "ONGOING" || status === "ONGOING_CONFIRM") {
        console.log("Checking project status...")
        const updatedProjectDetails = await getProject(loadedID);
        console.log('Updated project details status:', updatedProjectDetails.status)

        if (updatedProjectDetails.status === "CONFIRM") {
          console.log("Project status changed to CONFIRM. Pausing sourcing.");
          setStatus("CONFIRM"); // Ensure the status state is updated
          setIsSourcingActive(false); // Pause sourcing

        }
        if (updatedProjectDetails.status === "COMPLETED") {
          console.log("Project status changed to COMPLETED. Stopping sourcing.");
          setStatus("COMPLETED"); // Ensure the status state is updated
          setIsSourcingActive(false); // Stop sourcing
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [loadedID, isSourcingActive, status]);
  useEffect(() => {

    // Call the external fetchProjectDetails function if projectId is provided
    console.log('Effect running, projectId:', loadedID);
    if (loadedID && status !== 'CREATED') {
      console.log('Calling fetchProjectDetails');
      fetchProjectDetails();
      fetchSourcingsAndUpdateResults(loadedID);
    }
  }, [loadedID]); // Dependency array now includes projectId



  const handleHover = () => {
    setRevealLines(true);
  };
  const calculateSourcingResultsCounter = () => {
    return resultsData.reduce((acc, item) => {
      if (item.relevance === 'Yes') acc.Relevant += 1;
      else if (item.relevance === 'No') acc.NotRelevant += 1;
      else if (item.relevance === 'No Access') acc.NoAccess += 1;
      else if (item.relevance === 'Maybe') acc.MaybeRelevant += 1;
      return acc;
    }, { Relevant: 0, NotRelevant: 0, NoAccess: 0, MaybeRelevant: 0 }); // Initial value updated to include MaybeRelevant
  };

  const [sourcingResultsCounter, setSourcingResultsCounter] = useState(calculateSourcingResultsCounter());

  useEffect(() => {
    const newCounter = calculateSourcingResultsCounter();
    setSourcingResultsCounter(newCounter);
  }, [resultsData]);
  // Function to map relevance to the category
  const relevanceToCategory = {
    'Yes': 'Relevant',
    'No': 'NotRelevant',
    'Maybe': 'MaybeRelevant',
    'No Access': 'NoAccess',
  };

  const getRelevanceClass = (relevance) => {
    switch (relevance) {
      case 'Yes':
        return 'td-relevant';
      case 'Maybe':
        return 'td-maybe-relevant';
      case 'No':
        return 'td-not-relevant';
      case 'No Access':
        return 'td-no-access';
      default:
        return '';
    }
  };

  const filteredResults = resultsData.filter(item => selectedCategories.length === 0 || selectedCategories.includes(relevanceToCategory[item.relevance]));
  const handleCategoryClick = (category) => {
    // Temporarily store the current state
    const wasActive = selectedCategories.includes(category);
    const newCategories = selectedCategories.filter(c => c !== category);
    setSelectedCategoryFilter(category === selectedCategoryFilter ? null : category); // Toggle filter off if the same category is clicked again


    // Immediately remove the category to reset its animation if it was active
    if (wasActive) {
      setSelectedCategories(newCategories);
    } else {
      // If it wasn't active, reset all animations to sync them
      setSelectedCategories([]);
      setTimeout(() => {
        // Then, add back all previously active categories plus the newly clicked one
        setSelectedCategories([...newCategories, category]);
      }, 10); // A short delay is sufficient to reset the animations
    }
  };


  const handlePauseStopSourcing = async () => {
    // Determine the new status based on the current state
    const newStatus = isSourcingActive ? "PAUSED" : "ONGOING_CONFIRM";

    try {
      // Update the project status in the backend
      await updateProjectStatus(loadedID, newStatus);
      console.log(`Project status updated to ${newStatus}`);
      setStatus(newStatus); // Update the status state to reflect the new status

      // Toggle the sourcing active state to either pause or resume sourcing
      setIsSourcingActive(!isSourcingActive);

      if (isSourcingActive) {
        setIsPausing(true); // Set pausing state to true
        setTimeout(() => {
          setIsPausing(false); // Reset pausing state after 3 seconds
        }, 3000);
      }
      else {
        setIsStarting(true); // Set starting state to true
        setTimeout(() => {
          setIsStarting(false); // Reset starting state after 3 seconds
        }, 3000);
      }
    } catch (error) {
      console.error('Failed to update project status:', error);
    }
  };

  const handleCancelSourcing = async () => {
    if (window.confirm("Are you REALLY sure you want to cancel sourcing and delete the existing project?")) {
      // Check if sourcing is active and pause it before deletion
      if (isSourcingActive) {
        await updateProjectStatus(loadedID, "PAUSED");
        console.log("Sourcing paused successfully.");
      }
      await deleteExistingProject(); // Delete the project
      setIsSourcingActive(false); // Update state to reflect sourcing is no longer active
      setIsSourcingStarted(false); // Reset to initial state to allow restarting the sourcing process
      navigateHome(); // Navigate back to the home page
    }
  };
  const handleRuleValueChange = (rule, value) => {
    if (rule === "Min. employees count :" || rule === "No min. employees count") {
      setMinEmployees(value); // Update minEmployees state
    }
  };
  const getAnimationClass = (category) => {
    if (selectedCategories.includes(category)) {
      switch (category) {
        case 'Relevant':
          return 'animate-green-glow';
        case 'MaybeRelevant':
          return 'animate-orange-glow';
        case 'NotRelevant':
          return 'animate-red-glow';
        case 'NoAccess':
          return 'animate-gray-glow';
        default:
          return '';
      }
    }
    return '';
  };

  const handleMouseLeave = () => {
    setRevealLines(false);
  };

  const resultPagesOptions = [
    { value: '100', label: '10 pages / each keyword' },
    { value: '200', label: '20 pages / each keyword' },
    { value: '300', label: '30 pages / each keyword' },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      backgroundColor: '#333333',
      border: '1px solid #757575',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ccc' }, // border style on hover
      height: 'auto',
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: '1.2em', // Increase font size
      fontWeight: 'bold', // Make it bold
      color: '#333', // Darker color for better contrast
      backgroundColor: 'gray', // Light background color
      padding: '5px 50px', // Add some padding
    }),
    valueContainer: (provided, state) => {
      // Calculate the number of rows needed (assuming 4 items per row as an example)
      const numberOfRows = Math.ceil(state.getValue().length / 3);
      // Adjust the height based on the number of rows (30px per row as an example)
      const adjustedHeight = Math.max(40, numberOfRows * 30); // Adjust 40 and 30 to fit your design
      return {
        ...provided,
        padding: '0 6px',
        height: `${adjustedHeight}px`, // Set the dynamic height
        overflow: 'auto' // Add scroll if needed
      };
    },
    input: (provided) => ({
      ...provided,
      margin: '0px',
      color: 'white',

    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'auto',
    }),

    option: (provided, state) => ({
      ...provided,
      textAlign: 'center',
      backgroundColor: state.isSelected ? '#424242' : '#333333',
      color: '#e0e0e0',
      '&:hover': {
        backgroundColor: '#424242',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      fontSize: '14px',
      textAlign: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333333',
      color: '#e0e0e0',
      border: 'none',
      zIndex: 9999,
      overflow: 'visible'
    })
    , menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const customComponents = {
    SingleValue: ({ data }) => (
      <CountryFlag countryCode={data.code} svg style={{ marginRight: '10px', width: '2em', height: '100%' }} />
    ),
    MultiValueLabel: ({ data }) => (
      <div style={{ display: 'flex', alignItems: 'center' }} data-tooltip-id={`tooltip-${data.value}`}>
        <CountryFlag countryCode={data.code} svg style={{ marginRight: '10px', width: '2em', height: '100%' }} />
        <Tooltip
          id={`tooltip-${data.value}`}
          place="bottom"
          style={{ fontSize: '16px', padding: '10px', backgroundColor: '#333', color: '#fff', zIndex: 9999 }}
        >
          {data.label}
        </Tooltip>
      </div>
    ),
    // Add other custom components if needed
  };

  const calculateEstimatedTime = () => {
    const numberOfCountries = selectedCountries.length;
    const numberOfQueries = Object.keys(aiKeywords).length; // aiKeywords represents the queries
    const numberOfKeywords = keywords.length; // Assuming keywords is an array of keyword objects

    // Calculate queries per keyword per country
    const queriesPerKeywordPerCountry = Math.ceil(numberOfQueries / (numberOfKeywords * numberOfCountries));

    // Time for each query position
    const timePerQuery = [40, 30, 20, 10];

    let totalMinutesForOneKeywordAndCountry = 0;

    for (let i = 0; i < queriesPerKeywordPerCountry; i++) {
      // Add time for each query, using the timePerQuery array, defaulting to 20 if more queries than predefined times
      totalMinutesForOneKeywordAndCountry += (timePerQuery[i] || 10);
    }

    // Multiply by the number of keywords and countries
    const totalMinutes = totalMinutesForOneKeywordAndCountry * numberOfKeywords * numberOfCountries;

    // Convert total minutes to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };


  const customComponents2 = {
    SingleValue: ({ data }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CountryFlag countryCode="US" svg style={{ marginRight: '10px', width: '2em', height: '100%' }} />
        <span>{data.label}</span>
      </div>
    ),
    Option: ({ data, isFocused, ...props }) => {
      const countries = continentToCountriesMap[data.value] || []; // Get the list of countries for the region
      const countryFlags = countries
        .map(country => {
          const countryOption = countryOptions.find(opt => opt.label === country);
          return countryOption ? (
            <div
              key={countryOption.value}
              style={{ display: 'inline-block', margin: '2px', position: 'relative' }}
              data-tooltip-id={`tooltip-${countryOption.value}`}
            >
              <CountryFlag
                countryCode={countryOption.code}
                svg
                style={{ width: '1.5em', height: '1.5em', cursor: 'pointer' }}
              />
              <Tooltip
                id={`tooltip-${countryOption.value}`}
                place="top"
                content={countryOption.label}
                style={{
                  fontSize: '0.9em',
                  padding: '5px',
                  backgroundColor: '#333',
                  color: '#fff',
                  zIndex: 9999,
                }}
              />
            </div>
          ) : null;
        })
        .filter(Boolean); // Filter out any null values

      return (
        <div
          {...props.innerRef}
          {...props.innerProps}
          style={{
            padding: '10px',
            backgroundColor: isFocused ? '#424242' : '#333333', // Hover background
            color: isFocused ? '#fff' : '#e0e0e0', // Hover text color
            cursor: 'pointer', // Pointer cursor on hover
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold' }}>{data.label}</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '5px' }}>{countryFlags}</div>
          </div>
        </div>
      );
    },
  };

  function languageToCountryCode(languageCode) {
    const map = {
      'en': 'US', // English to United States
      'es': 'ES', // Spanish to Spain
      'fr': 'FR', // French to France
      'de': 'DE', // German to Germany
      'pt': 'PT', // Portuguese to Portugal
      'it': 'IT', // Italian to Italy
      'TR': 'TR', // Turkish to Turkey
      'VN': 'VN', // Vietnamese to Vietnam
      'CN': 'CN', // Chinese to China
      'AR': 'SA', // Arabic to Saudi Arabia
      'CS': 'CZ', // Czech to Czech Republic
      'HU': 'HU', // Hungarian to Hungary
      'ID': 'ID', // Indonesian to Indonesia
      'JA': 'JP', // Japanese to Japan
      'KO': 'KR', // Korean to South Korea
      'PL': 'PL', // Polish to Poland
      'RO': 'RO', // Romanian to Romania
      'RU': 'RU', // Russian to Russia
      'SR': 'RS', // Serbian to Serbia
      'TH': 'TH', // Thai to Thailand



      // Add more mappings as needed
    };

    return map[languageCode] || 'US'; // Default to 'US' if no mapping found
  }
  //const projectTest = fetchProject(36);
  const handleConfirmOverlay = async () => {
    setShowKeywordsOverlay(false)
    // Existing logic to start sourcing
    document.querySelectorAll('.left-panel, .middle-panel, .right-panel, .project-info, .sourcingButton, .home-button1').forEach(el => {
      el.classList.add('fade-out');
      setIsSourcingActive(true); // Update the sourcing status to true
    });

    setTimeout(async () => {
      try {
        console.log("Changing to extraction")
        const response = await updateProjectStatus(loadedID, "EXTRACTION");
        console.log('Project status updated to EXTRACTION:', response);
      } catch (error) {
        console.error('Failed to update project status to EXTRACTION:', error);
      }
      setIsSourcingStarted(true); // Update the sourcing status to true

    }, 3000); // Match this duration to your CSS animation duration
  };

  const renderRecap = () => (
    console.log('renderRecap'),
    <div className="recap-info-container" onMouseEnter={() => setIsRecapHovered(true)}
      onMouseLeave={() => setIsRecapHovered(false)}
    >
      <div className={`recap-info ${revealLines ? 'reveal-lines' : ''}`} onMouseLeave={handleMouseLeave}>
        <div className="see-settings" onMouseOver={handleHover}>
          <p>See the project settings</p>
          <img src={require('./assets/chevron-down.png')} alt="expand-arrow" height="10px" />   </div>

        <div className="first-line" >
          <h2>Code / Category </h2><p>{chargeCode} / {category}</p>
          <h2>Client's Name</h2><p>{clientName}</p>
          <h2>Request Date</h2><p>{requestDate}</p>
        </div>
        <div className="second-line">
          <h2>Keywords</h2><p>{keywords.join(', ')}</p>
          <h2>Rules</h2><p>{completedRules.join(', ')}</p>
          <h2>Minimal Contact Information</h2>
          <p>{searchDepthOptions.find(option => option.value === searchDepth)?.label || searchDepthOptions[1].label}</p>
        </div>
        <div className="third-line">
          <h2>Result Pages</h2>
          <p>{resultPagesOptions.find(option => option.value === resultPages)?.label || resultPagesOptions[0].label}</p>
          <h2>Countries</h2>
          <p>
            {selectedCountries.map((country, index, array) => (
              <span key={country.value}>
                <CountryFlag countryCode={country.code} svg style={{ marginRight: '5px', marginLeft: '5px' }} />
                {country.label}{index < array.length - 1 ? '  ' : ''}
              </span>
            ))}
          </p>
          <h2>Languages</h2>
          <p>
            {selectedLanguages.map((language, index, array) => (
              <span key={language.value}>
                <CountryFlag countryCode={languageToCountryCode(language.code)} svg style={{ marginRight: '5px' }} />
                {language.label}{index < array.length - 1 ? '  ' : ''}
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
  const searchDepthOptions = [
    { value: '1', label: 'Company Name' },
    { value: '2', label: 'Company Name + email' },
    { value: '3', label: 'Company Name + email + phone' },
    { value: '4', label: 'Company Name + email + phone + address' },
  ];

  const continentToCountriesMap = {
    'europe-west': ['France', 'Germany', 'United Kingdom', 'Belgium', 'Luxembourg', 'Netherlands', 'Switzerland', 'Austria', 'Ireland'],
    'europe-south': ['Spain', 'Italy', 'Portugal', 'Greece', 'Cyprus', 'Malta', 'Andorra'],
    'europe-north': ['Denmark', 'Norway', 'Sweden', 'Finland', 'Iceland', 'Estonia', 'Latvia', 'Lithuania'],
    'europe-east': ['Poland', 'Hungary', 'Czech Republic', 'Slovakia', 'Romania', 'Bulgaria', 'Croatia', 'Serbia', 'Slovenia', 'Montenegro', 'Bosnia and Herzegovina', 'North Macedonia', 'Albania', 'Moldova', 'Belarus', 'Ukraine', 'Russian Federation'],
    'north-america': ['United States', 'Canada'],
    'central-america': ['Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama', 'Mexico'],
    'caribbean': ['Cuba', 'Dominican Republic', 'Jamaica', 'Trinidad and Tobago'],
    'south-america': ['Brazil', 'Argentina', 'Chile', 'Colombia', 'Peru', 'Venezuela', 'Ecuador', 'Uruguay', 'Bolivia', 'Paraguay'],
    'north-africa': ['Morocco', 'Algeria', 'Tunisia', 'Libya', 'Egypt'],
    'west-africa': ['Senegal', 'Mali', 'Burkina Faso', 'Niger', 'Nigeria', 'Côte d\'Ivoire', 'Ghana', 'Benin', 'Togo', 'Sudan'],
    'central-africa': ['Cameroon', 'Chad', 'Central African Republic', 'Democratic Republic of the Congo', 'Republic of the Congo', 'Gabon'],
    'east-africa': ['Kenya', 'Tanzania', 'Uganda', 'Rwanda', 'Burundi', 'Ethiopia', 'Djibouti', 'Somalia', 'Eritrea'],
    'southern-africa': ['South Africa', 'Angola', 'Namibia', 'Botswana', 'Zambia', 'Zimbabwe', 'Malawi', 'Lesotho', 'Eswatini', 'Mozambique', 'Madagascar'],
    'east-asia': ['China', 'Japan', 'Korea, Republic of', 'Mongolia', 'Taiwan'],
    'southeast-asia': ['Indonesia', 'Malaysia', 'Singapore', 'Thailand', 'Philippines', 'Vietnam', 'Cambodia', 'Laos', 'Myanmar'],
    'south-asia': ['India', 'Pakistan', 'Bangladesh', 'Sri Lanka', 'Nepal'],
    'central-asia': ['Kazakhstan', 'Uzbekistan', 'Turkmenistan', 'Kyrgyzstan', 'Tajikistan'],
    'middle-eastern-countries': ['Bahrain', 'Cyprus', 'Egypt', 'Iran', 'Iraq', 'Israel', 'Jordan', 'Kuwait', 'Lebanon', 'Oman', 'Qatar', 'Saudi Arabia', 'Syrian Arab Republic', 'Turkey', 'United Arab Emirates', 'Yemen'],
    'australasia': ['Australia', 'New Zealand'],
    'melanesia': ['Papua New Guinea', 'Fiji', 'Solomon Islands'],
    'visegrad': ['Poland', 'Czech Republic', 'Slovakia', 'Hungary'],
    'balkanic-countries': ['Albania', 'Bosnia and Herzegovina', 'Bulgaria', 'Croatia', 'Kosovo', 'Montenegro', 'North Macedonia', 'Romania', 'Serbia', 'Slovenia'],
    'ex-urss-republics': ['Armenia', 'Azerbaijan', 'Belarus', 'Estonia', 'Georgia', 'Kazakhstan', 'Kyrgyzstan', 'Latvia', 'Lithuania', 'Moldova', 'Russian Federation', 'Tajikistan', 'Turkmenistan', 'Ukraine', 'Uzbekistan'],
    'scandinavia': ['Denmark', 'Norway', 'Sweden'],
    'baltic-countries': ['Estonia', 'Latvia', 'Lithuania'],

  };

  const allCountries = Array.from(new Set(Object.values(continentToCountriesMap).flat()));

  // Add the "World" option to the map
  continentToCountriesMap['world'] = allCountries;
  const countryOptions = [
    { value: 'AF', label: 'Afghanistan', code: 'AF' },
    { value: 'AL', label: 'Albania', code: 'AL' },
    { value: 'DZ', label: 'Algeria', code: 'DZ' },
    { value: 'AS', label: 'American Samoa', code: 'AS' },
    { value: 'AD', label: 'Andorra', code: 'AD' },
    { value: 'AO', label: 'Angola', code: 'AO' },
    { value: 'AI', label: 'Anguilla', code: 'AI' },
    { value: 'AQ', label: 'Antarctica', code: 'AQ' },
    { value: 'AG', label: 'Antigua and Barbuda', code: 'AG' },
    { value: 'AR', label: 'Argentina', code: 'AR' },
    { value: 'AM', label: 'Armenia', code: 'AM' },
    { value: 'AW', label: 'Aruba', code: 'AW' },
    { value: 'AU', label: 'Australia', code: 'AU' },
    { value: 'AT', label: 'Austria', code: 'AT' },
    { value: 'AZ', label: 'Azerbaijan', code: 'AZ' },
    { value: 'BS', label: 'Bahamas', code: 'BS' },
    { value: 'BH', label: 'Bahrain', code: 'BH' },
    { value: 'BD', label: 'Bangladesh', code: 'BD' },
    { value: 'BB', label: 'Barbados', code: 'BB' },
    { value: 'BY', label: 'Belarus', code: 'BY' },
    { value: 'BE', label: 'Belgium', code: 'BE' },
    { value: 'BZ', label: 'Belize', code: 'BZ' },
    { value: 'BJ', label: 'Benin', code: 'BJ' },
    { value: 'BM', label: 'Bermuda', code: 'BM' },
    { value: 'BT', label: 'Bhutan', code: 'BT' },
    { value: 'BO', label: 'Bolivia', code: 'BO' },
    { value: 'BA', label: 'Bosnia and Herzegovina', code: 'BA' },
    { value: 'BW', label: 'Botswana', code: 'BW' },
    { value: 'BV', label: 'Bouvet Island', code: 'BV' },
    { value: 'BR', label: 'Brazil', code: 'BR' },
    { value: 'IO', label: 'British Indian Ocean Territory', code: 'IO' },
    { value: 'BN', label: 'Brunei Darussalam', code: 'BN' },
    { value: 'BG', label: 'Bulgaria', code: 'BG' },
    { value: 'BF', label: 'Burkina Faso', code: 'BF' },
    { value: 'BI', label: 'Burundi', code: 'BI' },
    { value: 'KH', label: 'Cambodia', code: 'KH' },
    { value: 'CM', label: 'Cameroon', code: 'CM' },
    { value: 'CA', label: 'Canada', code: 'CA' },
    { value: 'CV', label: 'Cape Verde', code: 'CV' },
    { value: 'KY', label: 'Cayman Islands', code: 'KY' },
    { value: 'CF', label: 'Central African Republic', code: 'CF' },
    { value: 'TD', label: 'Chad', code: 'TD' },
    { value: 'CL', label: 'Chile', code: 'CL' },
    { value: 'CN', label: 'China', code: 'CN' },
    { value: 'CX', label: 'Christmas Island', code: 'CX' },
    { value: 'CC', label: 'Cocos (Keeling) Islands', code: 'CC' },
    { value: 'CO', label: 'Colombia', code: 'CO' },
    { value: 'KM', label: 'Comoros', code: 'KM' },
    { value: 'CG', label: 'Congo', code: 'CG' },
    { value: 'CD', label: 'Congo RDC', code: 'CD' },
    { value: 'CK', label: 'Cook Islands', code: 'CK' },
    { value: 'CR', label: 'Costa Rica', code: 'CR' },
    { value: 'CI', label: "Cote D'ivoire", code: 'CI' },
    { value: 'HR', label: 'Croatia', code: 'HR' },
    { value: 'CU', label: 'Cuba', code: 'CU' },
    { value: 'CY', label: 'Cyprus', code: 'CY' },
    { value: 'CZ', label: 'Czech Republic', code: 'CZ' },
    { value: 'DK', label: 'Denmark', code: 'DK' },
    { value: 'DJ', label: 'Djibouti', code: 'DJ' },
    { value: 'DM', label: 'Dominica', code: 'DM' },
    { value: 'DO', label: 'Dominican Republic', code: 'DO' },
    { value: 'EC', label: 'Ecuador', code: 'EC' },
    { value: 'EG', label: 'Egypt', code: 'EG' },
    { value: 'SV', label: 'El Salvador', code: 'SV' },
    { value: 'GQ', label: 'Equatorial Guinea', code: 'GQ' },
    { value: 'ER', label: 'Eritrea', code: 'ER' },
    { value: 'EE', label: 'Estonia', code: 'EE' },
    { value: 'ET', label: 'Ethiopia', code: 'ET' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)', code: 'FK' },
    { value: 'FO', label: 'Faroe Islands', code: 'FO' },
    { value: 'FJ', label: 'Fiji', code: 'FJ' },
    { value: 'FI', label: 'Finland', code: 'FI' },
    { value: 'FR', label: 'France', code: 'FR' },
    { value: 'GF', label: 'French Guiana', code: 'GF' },
    { value: 'PF', label: 'French Polynesia', code: 'PF' },
    { value: 'TF', label: 'French Southern Territories', code: 'TF' },
    { value: 'GA', label: 'Gabon', code: 'GA' },
    { value: 'GM', label: 'Gambia', code: 'GM' },
    { value: 'GE', label: 'Georgia', code: 'GE' },
    { value: 'DE', label: 'Germany', code: 'DE' },
    { value: 'GH', label: 'Ghana', code: 'GH' },
    { value: 'GI', label: 'Gibraltar', code: 'GI' },
    { value: 'GR', label: 'Greece', code: 'GR' },
    { value: 'GL', label: 'Greenland', code: 'GL' },
    { value: 'GD', label: 'Grenada', code: 'GD' },
    { value: 'GP', label: 'Guadeloupe', code: 'GP' },
    { value: 'GU', label: 'Guam', code: 'GU' },
    { value: 'GT', label: 'Guatemala', code: 'GT' },
    { value: 'GN', label: 'Guinea', code: 'GN' },
    { value: 'GW', label: 'Guinea-Bissau', code: 'GW' },
    { value: 'GY', label: 'Guyana', code: 'GY' },
    { value: 'HT', label: 'Haiti', code: 'HT' },
    { value: 'HM', label: 'Heard Island and McDonald Islands', code: 'HM' },
    { value: 'VA', label: 'Holy See (Vatican City State)', code: 'VA' },
    { value: 'HN', label: 'Honduras', code: 'HN' },
    { value: 'HK', label: 'Hong Kong', code: 'HK' },
    { value: 'HU', label: 'Hungary', code: 'HU' },
    { value: 'IS', label: 'Iceland', code: 'IS' },
    { value: 'IN', label: 'India', code: 'IN' },
    { value: 'ID', label: 'Indonesia', code: 'ID' },
    { value: 'IR', label: 'Iran', code: 'IR' },
    { value: 'IQ', label: 'Iraq', code: 'IQ' },
    { value: 'IE', label: 'Ireland', code: 'IE' },
    { value: 'IM', label: 'Isle of Man', code: 'IM' },
    { value: 'IL', label: 'Israel', code: 'IL' },
    { value: 'IT', label: 'Italy', code: 'IT' },
    { value: 'JM', label: 'Jamaica', code: 'JM' },
    { value: 'JP', label: 'Japan', code: 'JP' },
    { value: 'JE', label: 'Jersey', code: 'JE' },
    { value: 'JO', label: 'Jordan', code: 'JO' },
    { value: 'KZ', label: 'Kazakhstan', code: 'KZ' },
    { value: 'KE', label: 'Kenya', code: 'KE' },
    { value: 'KI', label: 'Kiribati', code: 'KI' },
    { value: 'KP', label: 'Korea, Democratic People\'s Republic of', code: 'KP' },
    { value: 'KR', label: 'Korea, Republic of', code: 'KR' },
    { value: 'KW', label: 'Kuwait', code: 'KW' },
    { value: 'KG', label: 'Kyrgyzstan', code: 'KG' },
    { value: 'LA', label: 'Lao PDR', code: 'LA' },
    { value: 'LV', label: 'Latvia', code: 'LV' },
    { value: 'LB', label: 'Lebanon', code: 'LB' },
    { value: 'LS', label: 'Lesotho', code: 'LS' },
    { value: 'LR', label: 'Liberia', code: 'LR' },
    { value: 'LY', label: 'Libya', code: 'LY' },
    { value: 'LI', label: 'Liechtenstein', code: 'LI' },
    { value: 'LT', label: 'Lithuania', code: 'LT' },
    { value: 'LU', label: 'Luxembourg', code: 'LU' },
    { value: 'MO', label: 'Macao', code: 'MO' },
    { value: 'MK', label: 'Macedonia', code: 'MK' },
    { value: 'MG', label: 'Madagascar', code: 'MG' },
    { value: 'MW', label: 'Malawi', code: 'MW' },
    { value: 'MY', label: 'Malaysia', code: 'MY' },
    { value: 'MV', label: 'Maldives', code: 'MV' },
    { value: 'ML', label: 'Mali', code: 'ML' },
    { value: 'MT', label: 'Malta', code: 'MT' },
    { value: 'MH', label: 'Marshall Islands', code: 'MH' },
    { value: 'MQ', label: 'Martinique', code: 'MQ' },
    { value: 'MR', label: 'Mauritania', code: 'MR' },
    { value: 'MU', label: 'Mauritius', code: 'MU' },
    { value: 'YT', label: 'Mayotte', code: 'YT' },
    { value: 'MX', label: 'Mexico', code: 'MX' },
    { value: 'FM', label: 'Micronesia, Federated States of', code: 'FM' },
    { value: 'MD', label: 'Moldova', code: 'MD' },
    { value: 'MC', label: 'Monaco', code: 'MC' },
    { value: 'MN', label: 'Mongolia', code: 'MN' },
    { value: 'ME', label: 'Montenegro', code: 'ME' },
    { value: 'MS', label: 'Montserrat', code: 'MS' },
    { value: 'MA', label: 'Morocco', code: 'MA' },
    { value: 'MZ', label: 'Mozambique', code: 'MZ' },
    { value: 'MM', label: 'Myanmar', code: 'MM' },
    { value: 'NA', label: 'Namibia', code: 'NA' },
    { value: 'NR', label: 'Nauru', code: 'NR' },
    { value: 'NP', label: 'Nepal', code: 'NP' },
    { value: 'NL', label: 'Netherlands', code: 'NL' },
    { value: 'NC', label: 'New Caledonia', code: 'NC' },
    { value: 'NZ', label: 'New Zealand', code: 'NZ' },
    { value: 'NI', label: 'Nicaragua', code: 'NI' },
    { value: 'NE', label: 'Niger', code: 'NE' },
    { value: 'NG', label: 'Nigeria', code: 'NG' },
    { value: 'NU', label: 'Niue', code: 'NU' },
    { value: 'NF', label: 'Norfolk Island', code: 'NF' },
    { value: 'MP', label: 'Northern Mariana Islands', code: 'MP' },
    { value: 'NO', label: 'Norway', code: 'NO' },
    { value: 'OM', label: 'Oman', code: 'OM' },
    { value: 'PK', label: 'Pakistan', code: 'PK' },
    { value: 'PW', label: 'Palau', code: 'PW' },
    { value: 'PA', label: 'Panama', code: 'PA' },
    { value: 'PG', label: 'Papua New Guinea', code: 'PG' },
    { value: 'PY', label: 'Paraguay', code: 'PY' },
    { value: 'PE', label: 'Peru', code: 'PE' },
    { value: 'PH', label: 'Philippines', code: 'PH' },
    { value: 'PN', label: 'Pitcairn', code: 'PN' },
    { value: 'PL', label: 'Poland', code: 'PL' },
    { value: 'PT', label: 'Portugal', code: 'PT' },
    { value: 'PR', label: 'Puerto Rico', code: 'PR' },
    { value: 'QA', label: 'Qatar', code: 'QA' },
    { value: 'RE', label: 'Reunion', code: 'RE' },
    { value: 'RO', label: 'Romania', code: 'RO' },
    { value: 'RU', label: 'Russian Federation', code: 'RU' },
    { value: 'RW', label: 'Rwanda', code: 'RW' },
    { value: 'BL', label: 'Saint Barthelemy', code: 'BL' },
    { value: 'SH', label: 'Saint Helena', code: 'SH' },
    { value: 'KN', label: 'Saint Kitts and Nevis', code: 'KN' },
    { value: 'LC', label: 'Saint Lucia', code: 'LC' },
    { value: 'MF', label: 'Saint Martin (French part)', code: 'MF' },
    { value: 'PM', label: 'Saint Pierre and Miquelon', code: 'PM' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', code: 'VC' },
    { value: 'WS', label: 'Samoa', code: 'WS' },
    { value: 'SM', label: 'San Marino', code: 'SM' },
    { value: 'ST', label: 'Sao Tome and Principe', code: 'ST' },
    { value: 'SA', label: 'Saudi Arabia', code: 'SA' },
    { value: 'SN', label: 'Senegal', code: 'SN' },
    { value: 'RS', label: 'Serbia', code: 'RS' },
    { value: 'SC', label: 'Seychelles', code: 'SC' },
    { value: 'SL', label: 'Sierra Leone', code: 'SL' },
    { value: 'SG', label: 'Singapore', code: 'SG' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)', code: 'SX' },
    { value: 'SK', label: 'Slovakia', code: 'SK' },
    { value: 'SI', label: 'Slovenia', code: 'SI' },
    { value: 'SB', label: 'Solomon Islands', code: 'SB' },
    { value: 'SO', label: 'Somalia', code: 'SO' },
    { value: 'ZA', label: 'South Africa', code: 'ZA' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { value: 'SS', label: 'South Sudan', code: 'SS' },
    { value: 'ES', label: 'Spain', code: 'ES' },
    { value: 'LK', label: 'Sri Lanka', code: 'LK' },
    { value: 'SD', label: 'Sudan', code: 'SD' },
    { value: 'SR', label: 'Suriname', code: 'SR' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', code: 'SJ' },
    { value: 'SZ', label: 'Swaziland', code: 'SZ' },
    { value: 'SE', label: 'Sweden', code: 'SE' },
    { value: 'CH', label: 'Switzerland', code: 'CH' },
    { value: 'SY', label: 'Syrian Arab Republic', code: 'SY' },
    { value: 'TW', label: 'Taiwan', code: 'TW' },
    { value: 'TJ', label: 'Tajikistan', code: 'TJ' },
    { value: 'TZ', label: 'Tanzania', code: 'TZ' },
    { value: 'TH', label: 'Thailand', code: 'TH' },
    { value: 'TL', label: 'Timor-Leste', code: 'TL' },
    { value: 'TG', label: 'Togo', code: 'TG' },
    { value: 'TK', label: 'Tokelau', code: 'TK' },
    { value: 'TO', label: 'Tonga', code: 'TO' },
    { value: 'TT', label: 'Trinidad and Tobago', code: 'TT' },
    { value: 'TN', label: 'Tunisia', code: 'TN' },
    { value: 'TR', label: 'Turkey', code: 'TR' },
    { value: 'TM', label: 'Turkmenistan', code: 'TM' },
    { value: 'TC', label: 'Turks and Caicos Islands', code: 'TC' },
    { value: 'TV', label: 'Tuvalu', code: 'TV' },
    { value: 'UG', label: 'Uganda', code: 'UG' },
    { value: 'UA', label: 'Ukraine', code: 'UA' },
    { value: 'AE', label: 'United Arab Emirates', code: 'AE' },
    { value: 'GB', label: 'United Kingdom', code: 'GB' },
    { value: 'US', label: 'United States', code: 'US' },
    { value: 'UY', label: 'Uruguay', code: 'UY' },
    { value: 'UZ', label: 'Uzbekistan', code: 'UZ' },
    { value: 'VU', label: 'Vanuatu', code: 'VU' },
    { value: 'VE', label: 'Venezuela', code: 'VE' },
    { value: 'VN', label: 'Vietnam', code: 'VN' },
    { value: 'VG', label: 'Virgin Islands, British', code: 'VG' },
    { value: 'VI', label: 'Virgin Islands, U.S.', code: 'VI' },
    { value: 'WF', label: 'Wallis and Futuna', code: 'WF' },
    { value: 'EH', label: 'Western Sahara', code: 'EH' },
    { value: 'YE', label: 'Yemen', code: 'YE' },
    { value: 'ZM', label: 'Zambia', code: 'ZM' },
    { value: 'ZW', label: 'Zimbabwe', code: 'ZW' }



  ];

  const regionOptions = [
    {
      label: 'Europe', options: [
        { value: 'europe-west', label: 'Western Europe' },
        { value: 'europe-south', label: 'Southern Europe' },
        { value: 'europe-north', label: 'Northern Europe' },
        { value: 'europe-east', label: 'Eastern Europe' },
        { value: 'balkanic-countries', label: 'Balkanic Countries' },
        { value: 'scandinavia', label: 'Scandinavia' },
        { value: 'baltic-countries', label: 'Baltic Countries' },
        { value: 'visegrad', label: 'Visegrad' },
      ]
    },
    {
      label: 'Asia', options: [
        { value: 'east-asia', label: 'East Asia' },
        { value: 'southeast-asia', label: 'Southeast Asia' },
        { value: 'south-asia', label: 'South Asia' },
        { value: 'central-asia', label: 'Central Asia' },
        { value: 'middle-eastern-countries', label: 'Middle Eastern Countries' },
      ]
    },
    {
      label: 'Africa', options: [
        { value: 'north-africa', label: 'North Africa' },
        { value: 'west-africa', label: 'West Africa' },
        { value: 'central-africa', label: 'Central Africa' },
        { value: 'east-africa', label: 'East Africa' },
        { value: 'southern-africa', label: 'Southern Africa' },
      ]
    },
    {
      label: 'Americas', options: [
        { value: 'north-america', label: 'North America' },
        { value: 'central-america', label: 'Central America' },
        { value: 'caribbean', label: 'Caribbean' },
        { value: 'south-america', label: 'South America' },
      ]
    },
    {
      label: 'Oceania', options: [
        { value: 'australasia', label: 'Australasia' },
        { value: 'melanesia', label: 'Melanesia' },
      ]
    },
    {
      label: 'World', options: [
        { value: 'world', label: 'World' },
      ]
    },
  ];
  const languageOptions = [
    { value: 'AR', label: 'Arabic', code: 'SA' },
    { value: 'ZH-CN', label: 'Chinese', code: 'CN' },
    { value: 'CS', label: 'Czech', code: 'CZ' },
    { value: 'US', label: 'English', code: 'us' },
    { value: 'FR', label: 'French', code: 'FR' },
    { value: 'DE', label: 'German', code: 'DE' },
    { value: 'HU', label: 'Hungarian', code: 'HU' },
    { value: 'ID', label: 'Indonesian', code: 'ID' },
    { value: 'IT', label: 'Italian', code: 'IT' },
    { value: 'JA', label: 'Japanese', code: 'JP' },
    { value: 'KO', label: 'Korean', code: 'KR' },
    { value: 'PL', label: 'Polish', code: 'PL' },
    { value: 'pt', label: 'Portuguese', code: 'PT' },
    { value: 'RO', label: 'Romanian', code: 'RO' },
    { value: 'RU', label: 'Russian', code: 'RU' },
    { value: 'SR', label: 'Serbian', code: 'RS' },
    { value: 'ES', label: 'Spanish', code: 'es' },
    { value: 'TH', label: 'Thai', code: 'TH' },
    { value: 'TR', label: 'Turkish', code: 'tr' },
    { value: 'vi', label: 'Vietnamese', code: 'VN' },
  ];


  const toggleRuleState = (website) => {
    const toggleWebsiteMap = {
      "Only selected countries": "All countries",
      "All countries": "Only selected countries",
      "No min. employees count": "Min. employees count :",
      "Min. employees count :": "No min. employees count",
      // Add more mappings as needed
    };

    // Update the excludedWebsites state based on the new toggled state
    setExcludedWebsites(prevWebsites => {
      return prevWebsites.map(currentWebsite => {
        if (currentWebsite === website) {
          // Toggle the website state based on the mapping
          return toggleWebsiteMap[currentWebsite] || currentWebsite; // Default to the same label if no mapping found
        }
        return currentWebsite;
      });
    });
    console.log('Excluded Websites:', excludedWebsites);
  };

  const formatOptionLabel = ({ value, label, code }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CountryFlag countryCode={code} svg style={{ marginRight: '10px', width: '2em', height: '100%' }} />
      {label}
    </div>
  );



  const handleAddKeyword = (keyword) => {
    console.log('handleAddKeyword');
    if (keyword) {
      setKeywords([...keywords, keyword]);
      setNewKeyword('');
      setLastAddedIndex(keywords.length); // Set the index of the new keyword
    }
  };
  const handleRemoveKeyword = (index) => {
    setRemovingIndex(index); // Mark the keyword for removal and start the animation

    // Wait for the animation to complete before removing the keyword
    setTimeout(() => {
      setKeywords(keywords.filter((_, i) => i !== index));
      setRemovingIndex(null); // Reset the removing index
    }, 500); // This duration should match the CSS animation duration
  };

  const handleRemoveAIKeyword = async (index, id) => {
    setRemovingAiIndex(index); // Mark the keyword for removal and start the animation

    // Wait for the animation to complete before removing the keyword
    setTimeout(async () => {
      try {
        await deleteKeyword(id);
        const updatedKeywords = { ...aiKeywords };
        delete updatedKeywords[index];
        setAiKeywords(updatedKeywords);
      } catch (error) {
        console.error('Failed to remove keyword:', error);
      }
      setRemovingAiIndex(null); // Reset the removing index
    }, 500); // This duration should match the CSS animation duration
  };
  const deleteExistingProject = async () => {
    try {
      console.log(`Deleting project with ID: ${loadedID}`);
      const response = await deleteProject(loadedID);
      console.log('Project deleted successfully:', response);
      setLoadedID(0); // Reset the project ID to indicate no project is currently selected
      // Handle success (e.g., display a success message or update the UI accordingly)
    } catch (error) {
      console.error('Failed to delete project:', error);
      // Handle error (e.g., display an error message)
    }
  };
  const createNewProject = async () => {
    const updatedRules = excludedWebsites.map(rule =>
      rule.startsWith("Min. employees count :") ? `Min. employees count : ${minEmployees}` : rule
    );
    setCompletedRules(updatedRules); // Update the state to indicate that the rules are completed

    try {
      const meData = await getMe(); // Fetch user data
      setBuyerName(meData.name); // Update the buyer name
      setBuyerEmail(meData.email); // Update the buyer email
      const projectData = {
        charge_code: chargeCode,
        client_name: clientName,
        buyer_name: meData.name, // Use the fetched buyer name
        buyer_email: meData.email, // Use the fetched buyer email
        user_id: meData.id, // Use the fetched user ID
        keywords: keywords,
        //ai_keywords: aiKeywords, // Serialize ai_keywords to JSON
        category: category,
        start_date: requestDate,
        countries: selectedCountries.map(country => country.label),
        languages: selectedLanguages.map(language => language.label),
        rules: updatedRules,
        min_contact_info: searchDepth,
        result_pages: resultPages,
      };

      console.log('Creating project with data:', projectData);
      const response = await createProject(projectData);
      console.log('Project created successfully:', response);
      setLoadedID(response.id); // Update the project ID
      setStatus('ONGOING');
      return response.id;
      // Handle success (e.g., display a success message or redirect to another page)
    } catch (error) {
      console.error('Failed to create project:', error);
      // Handle error (e.g., display an error message)
    }
  };

  const navigateHome = () => {
    console.log(loadedID, 'loadedID');
    if (!loadedID) {
      const isConfirmed = window.confirm("Are you sure you want to quit now? The current project will not be saved.");
      if (isConfirmed) {
        window.location.href = '/Project';
      }
    } else {
      window.location.href = '/Project';
    }
  };

  const handleStartSourcing = () => {
    let missingFields = [];
    if (!chargeCode) missingFields.push("Charge Code");
    if (!clientName) missingFields.push("Client's Name");
    if (!requestDate) missingFields.push("Request Date");
    if (!category) missingFields.push("Category");
    if (keywords.length === 0) missingFields.push("Keywords");
    if (selectedCountries.length === 0) missingFields.push("Countries");
    if (selectedLanguages.length === 0) missingFields.push("Languages");

    if (missingFields.length > 0) {
      alert("Please fill in the following fields : " + missingFields.join(", "));
    } else {
      populateAIKeywords();
    }
  };

  return (
    <div className="App"> {/* Wrap all components in a global div with the .App class */}
      {showKeywordsOverlay && (
        <div className="keywords-overlay">
          <div className="keywords-overlay-content">
            <h2>Queries review</h2>
            <p className="estimated-time">Estimated completion time: {calculateEstimatedTime()}</p>
            <ul>
              {Object.entries(aiKeywords).map(([index, { keyword, engine, id }]) => (
                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className={removingAiIndex === index ? 'keyword-remove-animate' : ''}>
                  {editingIndex === index ? (
                    <>
                      <input type="text" value={editingText} onChange={handleEditingTextChange} />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button onClick={() => handleConfirmEdit(id)}><HiCheck color="black" /></button>
                        <input type="text" value={engine} disabled style={{ width: '80px', marginLeft: '10px' }} />
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{keyword}</span>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="text" value={engine} disabled style={{ width: '80px', marginRight: '10px' }} />
                        <button onClick={() => handleEditKeyword(index, id)}><SlPencil color="black" /></button>
                        <button onClick={() => handleRemoveAIKeyword(index, id)}><SlTrash color="black" /></button>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <button onClick={() => handleConfirmOverlay()}>Confirm and start</button>
            <button onClick={async () => {
              setShowKeywordsOverlay(false);
              await deleteExistingProject(); // Await the deletion process
            }}>Cancel</button>
          </div>
        </div>
      )}
      <div className="header">
        <h1><span className="titleLetters"> D</span>ragon<span className="titleLetters">S</span>couter</h1>
      </div>
      {isSourcingStarted && renderRecap()}
      <div className="automated-sourcing-tool">
        {!isSourcingStarted ? (
          <>

            {/* New div for charge code, client's name, and request date on the same line */}
            <div className="project-info">
              <div className="charge-code" >
                <label>Charge Code</label>
                <input type="text" value={chargeCode} onChange={(e) => setChargeCode(e.target.value)} />
              </div>
              <div className="request-date" >
                <label>Date</label>
                <input
                  type="date"
                  value={requestDate || new Date().toISOString().split('T')[0]}
                  onChange={(e) => setRequestDate(e.target.value)}
                  className="date-input-style"
                  onClick={(e) => e.target.focus()} // Add this line
                />
              </div>
              <div className="client-name">
                <label>Client's name</label>
                <input type="text" value={clientName} onChange={(e) => setClientName(e.target.value)} />
              </div>
              <div className="category" > {/* New category field */}
                <label>Category</label>
                <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} />
              </div>

            </div>
            <div className={`main-content ${isSourcingStarted ? 'fade-out' : ''}`}>
              <div className={`left-panel`}>
                {/* Move the Add new keyword section to the top of the keyword list */}
                <div className="add-keyword">
                  <input
                    type="text"
                    placeholder="Enter new keyword"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                  />
                  <button onClick={() => handleAddKeyword(newKeyword)} style={{ backgroundColor: 'green' }}>+</button>
                </div>
                {/* Keyword list follows the Add new keyword section */}
                <div className="keyword-list">
                  {keywords.map((keyword, index) => {
                    const isAdding = index === lastAddedIndex;
                    const isRemoving = index === removingIndex;
                    let className = "keyword";
                    if (isAdding) className += " keyword-animate";
                    if (isRemoving) className += " keyword-remove-animate";

                    return (
                      <div key={index} className={className}>
                        {keyword}
                        <button onClick={() => handleRemoveKeyword(index)}>-</button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={`middle-panel`}>
                <div className="search-settings">
                  {/* 
                  <div className="search-depth">
                    <label>Minimal contact information</label>
                    <Select
                      options={searchDepthOptions}
                      onChange={(selectedOption) => setSearchDepth(selectedOption.value)}
                      value={searchDepthOptions.find(option => option.value === searchDepth) || searchDepthOptions[1]}
                      styles={customStyles}
                    />
                  </div>
                  <div className="result-pages">
                    <label>Result Pages</label>
                    <Select
                      options={resultPagesOptions}
                      onChange={(selectedOption) => setResultPages(selectedOption.value)}
                      value={resultPagesOptions.find(option => option.value === resultPages) || resultPagesOptions[0]}
                      styles={customStyles}

                    />
                  </div> */}

                  <div className="flagSelector">

                    <div className="country-selector">
                      <label>Countries</label>
                      <Select
                        options={countryOptions}
                        isMulti
                        formatOptionLabel={formatOptionLabel}
                        onChange={setSelectedCountries}
                        styles={customStyles}
                        menuPortalTarget={document.body} // Add this line
                        value={selectedCountries}
                        classNamePrefix="react-select" // Add this line
                        components={customComponents} // Add this line

                      />
                    </div>
                    <div className="zones-selector">
                      <label>Zone search</label>
                      <Select
                        options={regionOptions}
                        isMulti
                        components={customComponents2} // Add this line

                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(option => option.value);

                          const newSelectedCountries = [];
                          selectedValues.forEach(region => {
                            const countries = continentToCountriesMap[region] || [];
                            countries.forEach(country => {
                              if (!newSelectedCountries.includes(country)) {
                                newSelectedCountries.push(country);
                              }
                            });
                          });

                          // Preserve the order by building the array manually
                          const orderedSelectedCountries = [];
                          newSelectedCountries.forEach(country => {
                            const countryOption = countryOptions.find(option => option.label === country);
                            if (countryOption && !orderedSelectedCountries.some(c => c.label === country)) {
                              orderedSelectedCountries.push(countryOption);
                            }
                          });

                          setSelectedCountries(orderedSelectedCountries);
                        }}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        classNamePrefix="react-select"
                      />
                    </div>
                    <div className="search-engines-selector">
                      <label>Search Engines / Platforms</label>
                      <Select
                        options={[
                          { value: 'google', label: 'Google' },
                          { value: 'baidu', label: 'Baidu' }
                        ]}
                        isMulti
                        defaultValue={[{ value: 'google', label: 'Google' }]}
                        onChange={(selectedOptions) => {
                          const labels = selectedOptions ? selectedOptions.map(option => option.label.toUpperCase()) : [];
                          setSelectedEngines(labels);
                        }}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        classNamePrefix="react-select"
                      />
                    </div>
                    <div className="language-selector">
                      <label>Languages</label>
                      <Select
                        options={languageOptions}
                        isMulti
                        formatOptionLabel={formatOptionLabel}
                        onChange={setSelectedLanguages} // Consider updating this for languages
                        value={selectedLanguages} // And this too
                        styles={customStyles}
                        components={customComponents} // Add this line
                        menuPortalTarget={document.body} // Add this line
                        classNamePrefix="react-select" // Add this line
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`right-panel`}>

                <div className="rules">
                  <label>Rules</label>
                  {excludedWebsites.map((website, index) => {
                    // Determine if the website is in a "toggled-on" state based on its label
                    const isToggledOn = ["All countries", "Engineering companies", "Machine manufacturers", "Min. employees count :"].includes(website);
                    let className = `excluded-website ${isToggledOn ? 'toggled-on' : 'toggled-off'}`;
                    let showInput = false; // Flag to determine when to show the input field

                    // Determine if the input field should be shown based on the website's current state
                    if (["No minimal revenue", "Min. employees count :"].includes(website)) {
                      showInput = true; // Show input if the website is in a "toggled-on" state
                    }

                    return (
                      <div
                        key={index}
                        className={className}
                        onClick={() => toggleRuleState(website)}
                      >
                        <span style={{ textAlign: 'center' }}>{website}</span>
                        {showInput && (
                          <input
                            type="number"
                            style={{ marginLeft: '10px', width: '60px', textAlign: 'center' }}
                            placeholder="0"
                            value={minEmployees} // Bind input to minEmployees state
                            onClick={(e) => e.stopPropagation()} // Prevent click event from propagating
                            onChange={(e) => handleRuleValueChange(website, e.target.value)}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div> */}

            </div>


          </>
        ) : (<>
          <div className={`sourcing-results ${isSourcingStarted ? 'fade-in' : ''} ${isRecapHovered ? 'shrink' : ''}`}>

            <div className="progress-bar-container">
              {isSourcingActive && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ProgressBar
                    score={sourcingProgress}
                    label={
                      <span>
                        {filteredResults?.length === 0 ? "Fetching queries results..." : "Processing the websites..."}  <Grid fill="white" height="0.9em" width="2em" />
                      </span>
                    }
                    darkTheme="True"
                    className='progressBar'
                  />
                </div>
              )}
              {!isSourcingActive && (
                <ProgressBar
                  score={sourcingProgress}
                  label={status === "COMPLETED" ? "Process completed!" : "Paused"}
                  primaryColor="blue"
                  secondaryColor="cyan"
                  darkTheme="True"
                  className='progressBar'
                />
              )}
            </div>
            <div className="results-counter">
              <div onClick={() => handleCategoryClick('Relevant')} className={`relevant ${getAnimationClass('Relevant')}`}>
                <span className="label">Relevant</span>
                <span className="number">{sourcingResultsCounter.Relevant}</span>

              </div>
              <div onClick={() => handleCategoryClick('MaybeRelevant')} className={`maybe-relevant ${getAnimationClass('MaybeRelevant')}`}>
                <span className="label">Maybe Relevant</span>
                <span className="number">{sourcingResultsCounter.MaybeRelevant}</span>
              </div>
              <div onClick={() => handleCategoryClick('NotRelevant')} className={`not-relevant ${getAnimationClass('NotRelevant')}`}>
                <span className="label">Not Relevant</span>
                <span className="number">{sourcingResultsCounter.NotRelevant}</span>
              </div>

              <div onClick={() => handleCategoryClick('NoAccess')} className={`no-access ${getAnimationClass('NoAccess')}`}>
                <span className="label">No Access</span>
                <span className="number">{sourcingResultsCounter.NoAccess}</span>
              </div>
            </div>
            <div className="separator"></div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Relevance</th>
                    <th>Analysis</th>
                    <th>Company Website</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResults.map((item, index) => (

                    <tr key={index} onClick={() => window.open(item.website, '_blank')} style={{ cursor: 'pointer' }}>
                      <td>{item.companyName}</td>
                      <td className={getRelevanceClass(item.relevance)}>{item.relevance}</td>
                      <td>{item.analysis}</td>
                      <td> <a href={item.website}>{item.website} </a></td>
                    </tr>))}
                </tbody>
              </table>         </div> </div></>
        )}
        <div className="footer">
          {!isSourcingStarted ? (

            <>
              <div className="sourcing-controls">
                <button className="home-button1" onClick={navigateHome}><SlHome></SlHome></button>

                <button className="sourcingButton" onClick={handleStartSourcing} disabled={isGeneratingAIQueries}>
                  {isGeneratingAIQueries ? (
                    <>
                      Generating AI queries, please wait
                      <ThreeDots fill="gray" height="0.6em" width="4em" />
                    </>
                  ) : (
                    "Start sourcing"
                  )}
                </button>
              </div>
            </>) : (
            <div className="sourcing-controls">
              <button
                className={`pause-stop-button ${status === "CONFIRM" ? 'confirm-button-style' : ''}`}
                onClick={handlePauseStopSourcing}
                disabled={status === "COMPLETED" || isPausing || isStarting} // Disable button when starting
              >
                {isStarting ? "Starting..." : // Show "Starting..." when starting
                  status === "COMPLETED" ? "PROJECT COMPLETED" :
                    (status === "CONFIRM" ? "WAITING FOR USER CONFIRMATION... CLICK TO PROCEED" :
                      (isPausing ? "Pausing..." : (isSourcingActive ? "Pause / Stop " : "Resume")))}
              </button>
              {!isSourcingActive && (
                <>
                  <button className="export-button" onClick={handleExportSourcing}><MdOutlineFileDownload />
                  </button>
                  <Modal show={showModal} onClose={() => setShowModal(false)} onConfirm={confirmExport} />
                  <button className="cancel-button" onClick={handleCancelSourcing}><SlTrash></SlTrash></button>

                </>
              )}
              <button className="home-button2" onClick={navigateHome}><SlHome></SlHome></button>

            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default AutomatedSourcingTool; 